import { getPage } from "@/views/tradeInoldForNew/api";
import { ppmsApi, ctsApi } from "@/views/channel/api";

export default {
  name: "",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/policyRebate",
          name: "",
          title: "政策返利"
        },
        {
          path: "/policyRebate/rebateDetail",
          name: "",
          title: "政策返利详情"
        },
      ],
      contentGroupItem:[],
      pageLoadFlag:false,
      totalPages:'',// 全部页面
      totalRows:'0',
      currentPage:'',// 当前页
      nolistImg: require("@/assets/IntentionList/none.png"),
      // 分页条件
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      topInfo:{},
      type:0,//0:活动中 1:未确认 2:返利列表
      monthDate:'',// 上个页面传过来的日期
    }
  },
  mounted() {
    let info = this.$route.query.item?JSON.parse(this.$route.query.item):''
    this.topInfo = info?info:null
    this.type = this.$route.query.type
    this.monthDate = this.$route.query.monthDate

    this.$nextTick(()=>{
      this.getActivityList()
    })
  },

  methods: {
    gotoOrder(item){
      if (item.xtwIsExist && item.orderNumber){
        if (item.orderNumber.indexOf('BHO') > -1){
          this.$router.push({
            path: "/order/detail",
            query: {
              id:item.xtwOrderId,
            }
          });
          return
        }

        if (item.xtwOrderType){
          if (item.xtwOrderType == 'normal'){
            this.$router.push({
              path: "/channelOrder/detail",
              query: {
                id:item.xtwOrderId,
              }
            });
          }
          if (item.xtwOrderType == 'retial' || item.xtwOrderType == 'return'){
            this.$router.push({
              path: "/retailOrder/detailRetail",
              query: {
                id:item.xtwOrderId,
              }
            })
          }

        }
      }
    },
    // 查看完整方案
    gotoUrl(){

      if(this.topInfo.contractNumber){
        this.pageLoadFlag = true
        let data ={
          serviceCode:'getContractInfoByContractNo',
          contractNo:this.topInfo.contractNumber
        }
        ctsApi(data).then(res=>{
          window.open(res.data.data)
          // this.openPDFOnline(res.data.data,'application/pdf')
          this.pageLoadFlag = false
        })
      }

      // http://hpms-ssr.prdapp.hisense.com/?paramsObj={"contractTlpId":"HPMS_ZM_0125","contractNo":"HPMS_ZM_0125_202402_1180_1330201_2156999_01"}
      // window.open(`http://hpms-ssr.prdapp.hisense.com/?paramsObj={"contractTlpId":${this.topInfo.contractTlpId},"contractNo":${this.topInfo.contractNumber}`,'_blank')
    },
    openPDFOnline (base64, contentType){
      const arr = base64.split(',');
      const contentIndex = arr.length > 1 ? 1 : 0;
      const bstr = atob(arr[contentIndex]);
      let leng = bstr.length;
      let u8arr = new Uint8Array(leng);
      while(leng--){
        u8arr[leng] =  bstr.charCodeAt(leng)
      }
      const blob = new Blob([u8arr],{type:contentType});

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob );
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },


    ctsApiData(data){
      return ppmsApi(data)
    },
    // 获取活动中接口
    async getActivityList(){
      let serviceCode = ''
      let settlementNumber = ''
      let settlementType = ''
      switch (this.type){
        case '0':
          serviceCode = 'queryOngoingPolicyRewardList'
          settlementNumber = ''
          break;
        case '1':
          serviceCode = 'obtainSettlementRewardDetails'
          settlementNumber = this.topInfo.settlementNumber
          break;
        case '2':
          serviceCode = 'queryRewardDetailsForSettlementConfirmation'
          settlementType = this.topInfo.redemptionType?'1':'0'
          settlementNumber = this.topInfo.settlementNumber
          break;
        default:
          break;
      }
      let data = {
          companyCode: "",
          contractNumber: this.topInfo.contractNumber,
          merchantMdmCode: this.$store.state.user.userInfo.customer.customerCode,
          monthDate: serviceCode == 'obtainSettlementRewardDetails'?'': this.monthDate,
          pageNum: this.page.pageNum,
          pageSize: 10,
          productCode: "",
          serviceCode:serviceCode,
          settlementNumber,
          settlementType:settlementType?settlementType:''
      }
      this.pageLoadFlag = true
      const res = await this.ctsApiData(data)
      this.pageLoadFlag = false
      if (res.data.data){
        this.totalPages = res.data.data.pages
        this.currentPage = res.data.data.current
        if( this.page.pageNum > 1 && res.data.data.records.length>0) {
          this.contentGroupItem = this.contentGroupItem.concat(res.data.data.records);
        } else {
          this.contentGroupItem = res.data.data.records
        }
        if( res.data.data.pages == 0) {
          this.contentGroupItem = []
        }
      }
    },
    async getToCorimOrder() {
      // obtainSettlementRewardDetails
    },
    // 加载更多
    loadMore() {
      this.page.pageNum =  this.page.pageNum + 1
      // 活动中
      this.getActivityList()
    },
  }
};
